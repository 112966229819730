<template>
  <div class="d-remote-monitoring-monitorings-table w-100 h-100 overflow-auto">
    <b-container fluid="xl">
      <div class="d-flex align-items-center mb-4 mt-4">
        <div class="h3">
          Поиск мониторингов
        </div>
      </div>

      <CallingMonitoringFilters
        v-model="filters"
        :user="user"
        :selected-rows="selectedRows"
        :is-calling-functional="true"
        @on-change-sort-mode="changeSortMode"
        @on-reset="onResetFilterHandler"
        @on-confirm="onConfirmFilterHandler"
        @clear-select="clearSelect"
      />
      <template v-if="isFetchRequestCompleted">
        <div
          v-if="!monitorings.length"
          class="empty-list"
        >
          <p>Мониторинги не найдены</p>
        </div>

        <div
          v-else
          class="monitoring-table mt-8"
        >
          <div class="monitoring-table__header">
            Результаты поиска
          </div>
          <div class="table-wrapper">
            <template v-if="!isCallingFunctional">
              <div
                v-for="item in monitorings"
                :key="item.id"
                class="monitoring-table"
              >
                <div class="table__header">
                  <div class="header-cell checkbox-col">
                    <div
                      v-b-tooltip.hover.bottomleft
                      class="td-checkbox"
                      title="Выбрать все"
                    >
                      <b-form-checkbox
                        v-if="item.id === firstItem"
                        v-model="allRowSelected"
                        class="self-center"
                      />
                    </div>
                  </div>
                  <div class="header-cell">
                    Дата создания мониторинга
                  </div>
                  <div class="header-cell">
                    Пациент
                    <div class="ml-3">
                      <b-button
                        v-b-tooltip.hover.bottomleft.v-light
                        variant="light"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="p-1 border"
                        :disabled="!item.patient?.id"
                        title="Устройства"
                        @click="openPatientDeviceModal(item)"
                      >
                        <b-icon
                          icon="display"
                        />
                      </b-button>
                    </div>
                  </div>
                  <div class="header-cell">
                    Врач
                  </div>
                  <div class="header-cell">
                    Полис
                  </div>
                  <div class="header-cell">
                    Мониторинг
                  </div>
                  <div class="header-cell">
                    Финальный статус
                  </div>
                  <div class="header-cell action-col">
                    <div class="td-action">
                      <b-button
                        variant="light"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="mt-1 p-1 border"
                        title="Финальный статуc"
                        @click="openFinalStatusModal(item.patient.id, item.patient?.vtbForm?.finalStatus)"
                      >
                        <b-icon
                          icon="pencil-square"
                          class="text-info"
                        />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="table__row">
                  <div
                    class="flex flex-row table__substring"
                    :class="getTableRowClass(item)"
                  >
                    <div class="cell checkbox-col">
                      <b-form-checkbox
                        :id="`select-${item.id}`"
                        v-model="item.checked"
                        :name="`select-${item.id}`"
                      />
                    </div>
                    <div class="cell date-col">
                      {{ getDate(item.createdAt) }}
                    </div>
                    <div class="cell">
                      {{ item.patient?.fullName || '-' }}
                    </div>
                    <div class="cell">
                      {{ item.doctor?.fullName || '-' }}
                    </div>
                    <div class="cell">
                      <b-button
                        v-if="item.patient.policies.length > 0"
                        variant="light"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        class="mt-1 p-1 border"
                        title="Информация о полисах"
                        @click="openIndemnityModal(item.patient.id)"
                      >
                        <b-icon
                          icon="file-earmark-medical"
                          class="text-info"
                        />
                      </b-button>
                      <span v-else> - </span>
                    </div>
                    <div class="cell flex-col gap-2">
                      <span>{{ item.name }}</span>
                      <MonitoringStatusTag
                        class="self-center"
                        :status="item.status"
                      />
                    </div>
                    <div
                      class="cell flex-column"
                    >
                      <MonitoringStatusTag
                        v-if="item.patient?.vtbForm?.finalStatus"
                        :type="tagStatusType.FINAL_STATUS"
                        :final-status="item.patient?.vtbForm?.finalStatus"
                      />
                      <span v-else> - </span>
                    </div>
                    <div class="cell action-col">
                      <div class="td-action">
                        <b-button
                          v-if="!isCallingFunctional"
                          v-b-tooltip.hover.bottomleft.v-light
                          variant="light"
                          :type="$const.PRIMARY_BUTTON"
                          size="sm"
                          class="crm-border call"
                          title="СМС"
                          @click="sendSms(item.patient)"
                        >
                          <b-icon
                            icon="envelope"
                            style="color: #4B62C1;"
                          />
                        </b-button>
                        <b-button
                          v-if="!isCallingFunctional"
                          v-b-tooltip.hover.bottomleft.v-light
                          variant="light"
                          :type="$const.PRIMARY_BUTTON"
                          size="sm"
                          class="crm-border call"
                          title="Звонок"
                          @click="makeCall(item.patient)"
                        >
                          <b-icon
                            icon="telephone"
                            style="color: #4B62C1;"
                          />
                        </b-button>
                        <b-button
                          v-if="!isCallingFunctional"
                          v-b-tooltip.hover.bottomleft.v-light
                          variant="light"
                          :type="$const.PRIMARY_BUTTON"
                          size="sm"
                          class="crm-border call"
                          title="История сообщений"
                          @click="openMessagesHistory(item.patient.userName)"
                        >
                          <b-icon
                            icon="journal-text"
                            style="color: #4B62C1;"
                          />
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div
                    ref="scrollable"
                    class="scroll-block"
                  >
                    <div
                      v-for="appeal in item.patient?.vtbForm?.questionnaire"
                      :key="appeal.id"
                      class="appeal-block"
                    >
                      <div class="appeal-block__body">
                        <div class="appeal-block__date">
                          <span> {{ formattedDate(appeal.date) }} </span>
                          <b-icon
                            :icon="appeal.type === 'Sms'? 'envelope' : 'telephone' "
                            style="color: #4B62C1;"
                            class="ml-2"
                          />
                        </div>
                        <div class="appeal-block__message">
                          {{ appeal.comment }}
                        </div>
                      </div>
                      <div
                        v-if="appeal.type === 'Call'"
                        class="appeal-block__status"
                      >
                        <MonitoringStatusTag
                          :is-monitoring="false"
                          :type="tagStatusType.CALL"
                          :call-status="appeal.callStatus"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedRowCount !== 0"
                class="table-group-action"
              >
                <div
                  class="icon-block"
                  @click="incheckAllRows"
                >
                  <b-icon
                    icon="dash"
                  />
                </div>
                <span>{{ selectedRowCount }} выбрано</span>
                <div class="btns">
                  <b-button
                    v-if="isAllowToRemoveMonitoring"
                    variant="danger"
                    type="primary"
                    @click="removeMultyMonitotoringsHandler"
                  >
                    Удалить
                  </b-button>

                  <b-button
                    type="primary"
                    variant="light"
                    @click="incheckAllRows"
                  >
                    Отменить
                  </b-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <preloader v-else />
      <div
        v-if="totalRows > paginationParams.itemsPerPage"
        class="table-controls"
      >
        <div class="table-controls__perPage">
          <span class="label">Показать: </span>
          <b-form-select
            v-model="paginationParams.itemsPerPage"
            :options="perPageOptions"
          />
        </div>

        <b-pagination
          v-model="paginationParams.currentPage"
          :total-rows="totalRows"
          :per-page="paginationParams.itemsPerPage"
          aria-controls="monitoring-table"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import CallingMonitoringFilters from '@/components/Patients/CallingMonitoringFilters';
import MonitoringStatusTag from '@/components/Patients/MonitoringStatusTag';
import { declOfNum, utc0ToDateWithUserTimezone } from '@/helpers/utils';
import { remoteMonitoringService } from '@/services';
import Preloader from '@/components/Preloader';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';
import { format } from '@evd3v/date-fns';
import { TAG_STATUS_TYPE } from '@/helpers/consts';

export default {
  name: 'CallingMonitoring',
  components: {
    CallingMonitoringFilters,
    Preloader,
    MonitoringStatusTag,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isFetchRequestCompleted: false,
      allRowSelected: false,
      totalRows: 0,

      filters: {
        templateId: '',
        id: '',
        statuses: [],
        finalStatus: '',
        monitoringStatus: '',
        vtbFinalStatus: '',
        patientFio: '',
        patientId: '',
        patientPhone: '',
        ordering: 'Descending',
        skip: 0,
        take: 10,
        createdAt: null,
      },

      paginationParams: {
        itemsPerPage: 10,
        currentPage: 1,
      },

      perPageOptions: [
        { value: 10, text: '10 строк' },
        { value: 20, text: '20 строк' },
        { value: 50, text: '50 строк' },
        { value: 100, text: '100 строк' },
      ],

      monitorings: [],
      selectedSingleMonitoring: null,
      isDetailModalOpen: false,
      isRemoveSingleMonitoringModalOpen: false,
      isRemoveMultipleMonitoringModalOpen: false,
      isCallModalOpen: false,
      isSmsModalOpen: false,
      isMessagesHistoryModalOpen: false,
      detailMonitroingId: null,
    };
  },

  computed: {
    tagStatusType() {
      return TAG_STATUS_TYPE;
    },
    firstItem() {
      return this.monitorings[0].id;
    },
    isCallingFunctional() {
      return this.checkFeatureAccess({ name: 'Функционал для обзвона', url: '/remote-monitoring/monitorings-table/' });
    },
    user() {
      return this.$store.state.Auth.user;
    },
    role() {
      return this.$store.state.Auth.role;
    },
    sortTittle() {
      return this.filters.ordering === 'Ascending'
        ? 'Сначала старые'
        : 'Сначала новые';
    },
    selectedRowCount() {
      const checkedMonitorigns = this.monitorings.filter((m) => m.checked);
      return checkedMonitorigns.length || 0;
    },
    selectedRows() {
      return this.monitorings.filter((m) => m.checked);
    },
    isAllowToRemoveMonitoring() {
      const rolesAccess = [
        'Administrator',
        'Tech.operator',
        'gov.extra.admin.mo.full',
        'gov.doctor.small.admin.mo',
      ];
      return rolesAccess.includes(this.role);
    },
  },

  watch: {
    'filters.ordering': async function () {
      await this.fetchMonitoringsData();
    },
    allRowSelected(val) {
      this.monitorings = this.monitorings.map((i) => ({
        ...i,
        checked: val,
      }));
    },
    paginationParams: {
      deep: true,
      async handler() {
        const skip = (this.paginationParams.currentPage - 1)
          * this.paginationParams.itemsPerPage;

        this.filters.skip = skip;
        this.filters.take = this.paginationParams.itemsPerPage;
        await this.fetchMonitoringsData();
        this.allRowSelected = false;
      },
    },
    monitorings() {
      this.$nextTick(() => {
        this.scrollToRight();
      });
    },
  },
  async mounted() {
    this.isFetchRequestCompleted = true;
    await this.fetchMonitoringsData();
  },

  methods: {
    filterQuestionnaire(items) {
      if (items?.length) return items.sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    scrollToRight() {
      const { scrollable } = this.$refs;
      if (scrollable && scrollable.length > 0) {
        scrollable.forEach((element) => {
          element.scrollLeft = element.scrollWidth;
        });
      }
    },

    async onResetFilterHandler() {
      this.paginationParams.currentPage = 1;
      this.filters = {
        templateId: '',
        id: '',
        statuses: [],
        patientFio: '',
        patientId: '',
        patientPhone: '',
        ordering: 'Descending',
        transfer: null,
        skip: 0,
        take: this.paginationParams.itemsPerPage,
        currentPage: 0,
        finalStatus: '',
        monitoringStatus: '',
        vtbFinalStatus: '',
        createdAt: null,
      };
      await this.fetchMonitoringsData();
    },

    async onConfirmFilterHandler() {
      this.paginationParams.currentPage = 1;
      this.filters.skip = 0;
      await this.fetchMonitoringsData();
    },

    async fetchMonitoringsData() {
      this.isFetchRequestCompleted = false;

      const statuses = [];
      if (Array.isArray(this.filters.statuses) && this.filters.statuses.length) {
        this.filters.statuses.forEach((obj) => {
          statuses.push(obj.value);
        });
      }

      const requestParams = {
        ...this.filters,
        createdAt: this.filters.createdAt ? format(utc0ToDateWithUserTimezone(this.filters.createdAt), 'yyyy.MM.dd') : null,
        statuses,
      };

      const responce = await remoteMonitoringService.getMonitoringTableData(
        requestParams,
      );
      this.monitorings = responce.data;
      this.monitorings = this.monitorings.map((item) => ({
        ...item,
        questionnaire: this.filterQuestionnaire(item.patient?.vtbForm?.questionnaire),
      }));
      this.totalRows = +responce.headers['x-total-count'];
      this.isFetchRequestCompleted = true;
    },

    changeSortMode() {
      this.filters.ordering = this.filters.ordering === 'Ascending' ? 'Descending' : 'Ascending';
    },

    getTableRowClass(item) {
      if (item.checked) return 'table-cheked';
    },

    incheckAllRows() {
      this.monitorings = this.monitorings.map((i) => ({
        ...i,
        checked: false,
      }));
      this.allRowSelected = false;
    },

    uncheckRow(id) {
      if (id) {
        this.monitorings = this.monitorings.map((i) => ({
          ...i,
          checked: i.id === id ? false : i.checked,
        }));
      }
    },

    makeCall(patient) {
      uiService.showModal(MODALS.MONITORING_CREATE_CALL_MODAL, {
        name: 'MonitoringCreateCallModal',
        props: {
          isCall: true,
          patient,
        },
        actions: {
          refreshSearch: async () => {
            await this.onConfirmFilterHandler();
          },
        },
      });
    },
    sendSms(patient) {
      uiService.showModal(MODALS.MONITORING_CREATE_CALL_MODAL, {
        name: 'MonitoringCreateCallModal',
        props: {
          isCall: false,
          patient,
        },
        actions: {
          refreshSearch: async () => {
            await this.onConfirmFilterHandler();
          },
        },
      });
    },
    openMessagesHistory(phoneNumber) {
      uiService.showModal(MODALS.MONITORING_MESSAGES_HISTORY_MODAL, {
        name: 'MonitoringMessagesHistoryModal',
        props: {
          isCall: true,
          phoneNumber,
        },
      });
    },

    removeSingleMonitoringHandler(monitoring) {
      this.selectedSingleMonitoring = monitoring;
      this.isRemoveSingleMonitoringModalOpen = true;
    },

    async onConfirmSingleMonitoringRemove() {
      await this.removeMonitorings([this.selectedSingleMonitoring]);
      this.selectedSingleMonitoring = null;
    },

    async onConfirmMultyMonitoringRemove() {
      await this.removeMonitorings(this.selectedRows);
    },

    async removeMonitorings(monitorings) {
      try {
        const idsArray = monitorings.map((m) => m.id);
        const removeResponce = await remoteMonitoringService.removePatientMonitorings(
          idsArray,
        );

        if (removeResponce?.deletedQuantity) {
          const message = `${
            removeResponce?.deletedQuantity
          } ${declOfNum(removeResponce?.deletedQuantity, [
            'мониторинг',
            'мониторинга',
            'мониторингов',
          ])} успешно ${declOfNum(removeResponce?.deletedQuantity, [
            'удален',
            'удалено',
            'удалено',
          ])}`;

          this.$notificationService.showMessage({
            type: 'success',
            title: 'Успешно',
            message,
          });
        }

        if (removeResponce?.notDeletedQuantity) {
          const message = `${
            removeResponce?.notDeletedQuantity
          } ${declOfNum(removeResponce?.notDeletedQuantity, [
            'мониторинг',
            'мониторинга',
            'мониторингов',
          ])} удалить не получилось Проверьте, что выбранные мониторинги завершены (отменены) и повторите попытку.
          `;

          this.$notificationService.showMessage({
            type: 'warning',
            title: 'Ошибка',
            message,
          });
        }
      } catch (e) {
        console.log(e);
        this.$notificationService.showMessage({
          type: 'warning',
          title: 'Ошибка',
          message:
            'Произошла ошибка при удалении мониторинга. Проверьте, что выбранные мониторинги завершены (отменены) и повторите попытку.',
        });
      } finally {
        await this.fetchMonitoringsData();
      }
    },

    removeMultyMonitotoringsHandler() {
      uiService.showModal(MODALS.REMOVE_MULTY_MONITORINGS_MODAL, {
        name: 'RemoveMultyMonitoringsModal',
        props: {
          monitorings: this.selectedRows,
        },
        actions: {
          onUncheck: (id) => {
            this.uncheckRow(id);
          },
          onConfirm: () => {
            this.onConfirmMultyMonitoringRemove();
          },
          onClear: () => {
            this.clearSelectedMonitoring();
          },
        },
      });
    },
    openIndemnityModal(patientId) {
      uiService.showModal(MODALS.MONITORING_POLICIES_MODAL, {
        name: 'MonitoringMessagesHistoryModal',
        props: {
          patientId,
        },
      });
    },
    openFinalStatusModal(patientId, previousFinalStatus) {
      uiService.showModal(MODALS.FINAL_STATUS_MODAL, {
        name: 'FinalStatusModal',
        props: {
          patientId,
          previousFinalStatus,
        },
        actions: {
          refreshSearch: async () => {
            await this.onConfirmFilterHandler();
          },
        },
      });
    },
    openPatientDeviceModal(item) {
      uiService.showModal(MODALS.PATIENT_DEVICE_MODAL, {
        name: 'PatientDeviceModal',
        props: {
          personId: item.patient?.id,
          fio: item.patient?.fullName,
        },
      });
    },
    clearSelect() {
      this.allRowSelected = false;
    },
    getDate(date) {
      if (date) return format(utc0ToDateWithUserTimezone(date), 'dd.MM.yyyy HH:mm');
      return '-';
    },
    formattedDate(date) {
      if (date) return format(utc0ToDateWithUserTimezone(date), 'dd-MM-yy');
      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.d-remote-monitoring-monitorings-table {
  .td-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 35px;
  }
  .td-checkbox {
    width: 24px;
  }
  .cell,
  .header-cell {
    padding: 6px 12px;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 16px;
    &:not(:last-child) {
      border-right: 1px solid #dee2e6;
    }
  }
  .header-cell {
    flex-direction: row;
    align-items: center;
    font-weight: 600;
  }
  .cell {
    align-items: center;
  }
  .table {
    &__header {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #dee2e6;
    }
    &__row {
      display: flex;
      flex-direction: column;
      border-bottom: 1.5px solid #dee2e6;
    }
    &__substring {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #dee2e6;
    }
  }
  .action-col,
  .checkbox-col {
    flex: 0.1;
  }
  .checkbox-col {
    padding: 0 8px 0 16px;
  }
  .scroll-block {
    overflow: auto;
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 6px;
  }
  .appeal-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    min-width: 200px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    gap: 6px;
    padding: 6px;
    &__body {
      display: flex;
      gap: 6px;
      flex-direction: column;
    }
    &__date {
      text-align: center;
      font-weight: 600;
    }
    &__status {
      display: flex;
      justify-content: center;
    }
  }
  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    p {
      color: #adb5bd;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-top: 8px;
      line-height: 150%; /* 24px */
    }
  }

  .monitoring-table {
    &__header {
      color: #323232;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }

    .table-wrapper {
      display: block;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #dee2e6;
      table {
        border-collapse: collapse;
        background: #fff;
        border-spacing: 0;
        border-radius: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        color: #212529;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 0;

        thead {
          th {
            border-top: 0;
            border-bottom: 2px solid #212529;
          }
        }

        .active-link {
          color: #0d6efd;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          cursor: pointer;
        }

        svg {
          cursor: pointer;
        }

        .table-cheked {
          background: rgba(0, 0, 0, 0.075);
        }
      }

      .table-controls {
        display: flex;
        justify-content: space-between;
        padding: 16px;

        .pagination {
          margin-bottom: 0;
        }

        &__perPage {
          display: flex;
          align-items: center;
          span.label {
            margin-right: 16px;
            color: #6c757d;
            font-family: Muller;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .table-group-action {
        padding: 16px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ced4da;
        svg {
          cursor: pointer;
        }

        .btns {
          display: flex;
          align-items: center;
          column-gap: 8px;
          margin-left: 24px;
        }
      }
    }
  }
  .sort {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #212529;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
  }
  .table-controls {
    display: flex;
    padding: 16px;

    .pagination {
      margin: 0 0 0 20px;
    }

    &__perPage {
      display: flex;
      align-items: center;
      span.label {
        margin-right: 16px;
        color: #6c757d;
        font-family: Muller;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .icon-block {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    stroke: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
