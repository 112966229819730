<template>
  <div class="monitorings-table-filters">
    <div
      class="filters-line"
    >
      <v-select
        v-model="filters.templateId"
        :reduce="item => item.id"
        :options="templatesList"
        placeholder="Шаблон мониторинга"
        label="name"
        class="crm-select"
      >
        <template #no-options>
          Ничего не найдено
        </template>
      </v-select>

      <base-input
        v-model="filters.id"
        class="form-control"
        placeholder="ID мониторинга"
        @keyup.native.enter="onConfirm"
      />

      <multiselect
        v-model="filters.statuses"
        class="multiselect-custom"
        :multiple="true"
        :hide-selected="true"
        :close-on-select="true"
        label="title"
        track-by="value"
        select-label="Выбрать"
        selected-label="Выбрано"
        deselect-label="Нажмите, чтобы удалить"
        placeholder="Статус мониторинга"
        :options="monitoringStatuses"
      >
        <span slot="noResult">Не удалось найти</span>
      </multiselect>
    </div>

    <div class="filters-line">
      <base-input
        v-model="filters.patientFio"
        class="form-control"
        placeholder="ФИО пациента"
        @keyup.native.enter="onConfirm"
      />

      <base-input
        v-model="filters.patientId"
        class="form-control"
        placeholder="ID пациента"
        :mask="idMask"
        @input="onIdFieldInput('patientId')"
      />

      <base-input
        v-model="filters.patientPhone"
        class="form-control"
        placeholder="Телефон пациента"
        :mask="phoneMask"
        @keyup.native.enter="onConfirm"
      />
    </div>
    <template v-if="isCallingFunctional">
      <div class="sort-block">
        <div class="sort-block__body">
          <base-date-picker
            v-model="filters.createdAt"
            placeholder="Дата создания мониторинга"
            :max-date="maxDate"
          />
        </div>
        <div class="sort-block__select">
          <v-select
            v-model="filters.vtbFinalStatus"
            :reduce="item => item.value"
            :options="finalStatuses"
            placeholder="Финальный статус"
            label="title"
            class="crm-select"
          >
            <template #no-options>
              Ничего не найдено
            </template>
          </v-select>
        </div>
        <div class="sort-block__select">
          <v-select
            v-model="filters.vtbStatus"
            :reduce="item => item.value"
            :options="monitoringStatusTypes"
            placeholder="Актуальный статус звонка"
            label="title"
            class="crm-select"
          >
            <template #no-options>
              Ничего не найдено
            </template>
          </v-select>
        </div>
      </div>
    </template>
    <div
      :class="isCallingFunctional ? 'filters-controls-end' : 'filters-controls'"
    >
      <div class="filters-controls__btns">
        <b-button
          v-if="isCallingFunctional"
          type="primary"
          variant="primary"
          :disabled="selectedRows.length === 0"
          @click="openSmsModal"
        >
          Направить СМС
        </b-button>
        <b-button
          type="primary"
          variant="secondary"
          @click="onReset"
        >
          Сбросить фильтры
        </b-button>
        <b-button
          type="primary"
          variant="primary"
          @click="onConfirm"
        >
          Показать
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput';
import { FINAL_STATUS_TYPE, MONITORING_STATUS_TYPE, REMOTE_MONITORING_STATUS_STRING_FULL } from '@/helpers/consts';
import { BaseDatePicker } from '@/components/base';
import { remoteMonitoringService } from '@/services';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'CallingMonitoringFilters',
  components: { BaseInput, BaseDatePicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    selectedRows: {
      type: Array,
      default: () => ([]),
    },
    isCallingFunctional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneMask: [
        '+',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      templatesList: [],
      specializationList: [],
      idMask: Array(50).fill(/[0-9]/),
      maxDate: new Date(),
    };
  },
  computed: {
    transferOptions() {
      return [
        { text: 'Все', value: 'All' },
        { text: 'Текущие', value: 'NotDelegated' },
        {
          text: 'Делегированные от врача',
          value: 'Delegated',
        },
      ];
    },
    filters: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
    monitoringStatuses() {
      return REMOTE_MONITORING_STATUS_STRING_FULL;
    },
    finalStatuses() {
      return FINAL_STATUS_TYPE;
    },
    monitoringStatusTypes() {
      return MONITORING_STATUS_TYPE;
    },
  },

  async mounted() {
    await this.fetchDataForSelectors();
  },

  methods: {
    changeSortMode() {
      this.$emit('on-change-sort-mode');
    },
    onReset() {
      this.$emit('on-reset');
      this.$emit('clear-select');
    },
    onIdFieldInput(fieldName) {
      this.filters[fieldName] = this.filters[fieldName].replace(/[^0-9]/g, '');
    },

    onConfirm() {
      this.$emit('on-confirm');
    },

    async fetchDataForSelectors() {
      this.templatesList = await remoteMonitoringService.getRemoteMonitoringTemplatesList();
      this.specializationList = await remoteMonitoringService.fetchSpecialization();
    },

    openSmsModal() {
      uiService.showModal(MODALS.MONITORING_CREATE_CALL_MODAL, {
        name: 'MonitoringCreateCallModal',
        props: {
          isCall: false,
          monitorings: this.selectedRows,
        },
        actions: {
          refreshSearch: () => {
            this.$emit('on-confirm');
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.crm-select {
  .vs__dropdown-toggle {
    background: white;
    border: 1px solid #ced4da;
  }
  .vs__dropdown-menu {
    background: white;
    height: 155px;
  }
  .vs__selected {
    font-size: 16px;
  }

  .vs__search {
    font-size: 16px;
    background: white;
  }
}
.monitorings-table-filters {
  row-gap: 16px;
  display: flex;
  flex-direction: column;
  .filters-line {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
  }
  .crm-base-input {
    font-size: 16px;
  }

  .filters-controls {
    display: flex;
    justify-content: space-between;
    &-end {
      display: flex;
      justify-content: end;
    }

    &__btns {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .custom-control-label::before {
      top: 0;
    }

    .custom-control-label::after {
      top: 0;
    }
  }
  .sort {
    &-block {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
      &__body {
        .date-picker {
          input {
            font-size: 16px;
            &::placeholder {
              color: #707070;
            }
          }
          height: 40px;
        }
      }
      &__title {
        font-size: 18px;
        font-weight: 600;
      }
      &__elements {
        display: flex;
        font-size: 16px;
        flex-direction: column;
      }
      &__select {
        width: 100%;
      }
      &__radio-group {
        margin-left: 12px;
      }
    }
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #212529;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
  }
}
.multiselect__tags {
  height: 40px;
  padding: 10px 40px 0 8px;
  border: 1px solid #ced4da;
}
.multiselect-custom {
  & ::v-deep {
    .multiselect__input {
      line-height: unset;
      padding: unset;
      margin: unset;
      font-size: 16px;

      position: unset;
    }
    .multiselect__select:before {
      top: 85%;
    }
    .multiselect__select {
      width: 25px;
      height: 25px
    }
  }
}
.multiselect__option--highlight,
.multiselect__option--highlight:after,
.multiselect__tag {
  background: #007bff;
}
.multiselect__tag-icon:hover {
  background: #0063cd;
}
.multiselect__placeholder {
  color: #212529;
  font-weight: 300;
  font-size: 16px;
  padding: 0;
}
.form-control {
  height: 40px;
  .crm-base-input {
  border: none !important;
  }
}
</style>
