<template>
  <b-button
    :variant="tagSettings.variant"
    class="rm-form-tag"
  >
    {{
      tagSettings.title
    }}
  </b-button>
</template>

<script>
import { MONITORING_STATUS_TYPE, TAG_STATUS_TYPE } from '@/helpers/consts';

export default {
  name: 'MonitoringStatusTag',
  props: {
    status: {
      type: Number,
      default: null,
    },
    finalStatus: {
      type: String,
      default: '',
    },
    callStatus: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: TAG_STATUS_TYPE.MONITORING,
    },
  },
  computed: {
    tagSettings() {
      if (this.type === TAG_STATUS_TYPE.MONITORING) {
        const mapping = {
          0: {
            title: 'Активен',
            variant: 'outline-success',
          },
          1: {
            title: 'Завершён',
            variant: 'outline-secondary',
          },
          2: {
            title: 'Отказ',
            variant: 'outline-danger',
          },
          3: {
            title: 'Отменён',
            variant: 'outline-danger',
          },
          4: {
            title: 'На паузе',
            variant: 'outline-warning',
          },
          5: {
            title: 'Создан',
            variant: 'outline-primary',
          },
        };

        return mapping[this.status];
      }
      if (this.type === TAG_STATUS_TYPE.FINAL_STATUS) {
        const mapping = {
          Issued: {
            title: 'Оформлен',
            variant: 'outline-success',
          },
          NotReached: {
            title: 'Не дозвонились',
            variant: 'outline-secondary',
          },
          Refusal: {
            title: 'Отказ',
            variant: 'outline-danger',
          },
        };

        return mapping[this.finalStatus];
      }
      const callStatus = MONITORING_STATUS_TYPE.find((item) => item.value === this.callStatus);
      return {
        title: callStatus ? callStatus.title : 'Неизвестный статус',
        variant: 'outline-primary',
      };
    },
  },
};
</script>

<style lang="scss">
button.rm-form-tag {
  pointer-events: none;
  height: 23px;
  padding: 0 8px;
  min-width: 70px;
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
}
</style>
